import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { footerBg, miniLogo } from "../assets";

export default function Footer() {
  const { t } = useTranslation(["global"]);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url('${footerBg}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          p: "1em 2em",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: "1em",
        }}
      >
        <img
          onClick={() => navigate("/")}
          src={miniLogo}
          alt="NEO Logo"
          style={{ width: "80px", cursor: "pointer" }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "1em",
            "& Button": {
              color: "#FFF",
              "&:hover": {
                color: "primary.main",
                bgcolor: "transparent",
              },
            },
          }}
        >
          <Button variant="text">{t("Privacy Policy")}</Button>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#FFF", bgcolor: "#FFF" }}
          />

          <Button variant="text">{t("Terms of Service")}</Button>

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#FFF", bgcolor: "#FFF" }}
          />

          <Button variant="text">{t("Contact Us")}</Button>
        </Box>

        <Box sx={{ width: "80px" }}></Box>
      </Box>

      <Box sx={{ bgcolor: "#0F151C" }}>
        <Typography
          variant="body1"
          sx={{ color: "#FFF", textAlign: "center", p: "5px" }}
        >
          {t("© 2024 NEO. All Rights Reserved")}
        </Typography>
      </Box>
    </>
  );
}
